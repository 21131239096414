import { graphql } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import "../../styles/blog.scss"

// local imports
import BlogLayout from "../../components/layout-blog"
import { SEO } from "../../components/seo"
import Tags from "../../components/tagsPanel"
import Post from "../../templates/post"

// MUI components
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (props.location && window.location.pathname === "/blog") {
        // scroll to top of element with id top #top - but only if there is no hash in the url
        if (!window.location.hash) {
          document.getElementById("top").scrollIntoView()
        }
      }
    }
  }, [props.location])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid
          container
          sx={{ gap: "2rem", "@media (max-width: 600px)": { gap: "1.5rem" } }}
        >
          {children}
        </Grid>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Index = ({ data, location }) => {
  const siteTitle = data?.site.siteMetadata?.title || `Title`
  const posts = data?.allMdx.nodes || []

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  if (posts.length === 0) {
    return (
      <BlogLayout location={location} title={siteTitle}>
        <SEO title="Blog" />
        <p>
          No blog posts found.
        </p>
      </BlogLayout>
    )
  }

  return (
    <BlogLayout location={location} title={siteTitle}>
      <SEO title="Blog" />
      <Container
        maxWidth="string"
        disableGutters
        sx={{
          maxWidth: "692px",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          "@media (max-width: 600px)": {
            gap: "1.5rem",
            px: "1.5rem",
          },
        }}
      >
        {/* category tabs selector */}
        {/* <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="nav tabs example"
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "text.primary",
                height: "1px",
              },
            }}
          >
            <Tab
              label="Blog"
              href="/blog"
              // {...a11yProps(0)}
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            
            <Tab
              label="Gear List"
              component="a"
              href="/gear"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            <Tab
              label="My Links"
              component="a"
              href="/links"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
          </Tabs>
        </Box> */}

        <Tags />
        {/* all posts panel  */}
        <TabPanel value={value} index={0} key={"all"}>
          {posts.map(post => {
            if (post.frontmatter.featured === true && post.frontmatter.category === "blog") {
              return (
                <Grid
                  className="featured"
                  item
                  xs={12}
                  key={post.id}
                  sx={{
                    "&:last-child": { "& > hr": { display: "none" } },
                  }}
                >
                  <Post data={post} featured={true} />
                </Grid>
              )
            }
            if (post.frontmatter.featured !== true && post.frontmatter.category === "blog") {
              return (
                <Grid
                  item
                  xs={12}
                  key={post.id}
                  sx={{
                    "&:last-child": { "& > hr": { display: "none" } },
                  }}
                >
                  <Post data={post} />
                  <Divider
                    sx={{ pt: 4, "@media (max-width: 600px)": { pt: "1.5rem" } }}
                  />
                </Grid>
              )
            }
          })}
        </TabPanel>

        {/* categories panels */}
        {data.allMdx.group.map((category, index) => (
          <TabPanel value={value} index={index + 1} key={category.fieldValue}>
            {posts.map(post => {
              if (post.frontmatter.category === category.fieldValue) {
                return (
                  <Grid
                    item
                    xs={12}
                    key={post.id}
                    sx={{
                      "&:last-child": { "& > hr": { display: "none" } },
                    }}
                  >
                    <Post data={post} />
                    <Divider
                      sx={{
                        pt: 4,
                        "@media (max-width: 600px)": { pt: "1.5rem" },
                      }}
                    />
                  </Grid>
                )
              }
              return
            })}
          </TabPanel>
        ))}
      </Container>
    </BlogLayout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 200) {
      group(field: frontmatter___category) {
        fieldValue
      }
      nodes {
        id
        excerpt
        fields {
          slug
        }
        timeToRead
        frontmatter {
          category
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          featuredImage {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5)
            }
            name
          }
          featured
        }
      }
    }
  }
`
